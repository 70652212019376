import { decode } from 'base-64'
global.atob = decode // Fix for https://github.com/auth0/jwt-decode/issues/241
import { Spinner } from '@bp/ui'
import FeedInner from './feedInner'
import { useStream } from 'app/lib/stream/useStream'

export default function Feed({ userId, feedSlug }) {
  const feedsConnected = useStream((s) => s.feedsConnected)

  if (!feedsConnected) {
    return <Spinner />
  }

  return <FeedInner feedSlug={feedSlug} userId={userId} />
}
