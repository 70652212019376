import { Button, Spinner, Text, XStack, YStack, useMedia, useThemeName } from '@bp/ui'
import { connect } from 'getstream'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { FlatList } from 'react-native'
import Post from './post'
import { useEffect } from 'react'
import { SolitoImage } from 'solito/image'
import emptyFeed from './emptyFeed.png'
import emptyFeedDark from './emptyFeedDark.png'
import { useLink } from 'solito/link'
import { useStream } from 'app/lib/stream/useStream'
import useInViewport from './inView'

export default function FeedInner({ userId, feedSlug }) {
  const queryClient = useQueryClient()
  const client = useStream((s) => s.feeds)
  const feed = client.feed(feedSlug, userId)

  const limit = 10
  const fetchFeed = async ({ pageParam = 0 }) => {
    const response = await feed.get({
      limit,
      offset: pageParam,
      enrich: true,
      reactions: { own: true, counts: true, recent: true },
    })
    return {
      results: response.results,
      nextPage: pageParam + limit,
      hasMore: response.next,
    }
  }
  const {
    data: gsFeedData,
    fetchNextPage,
    hasNextPage: gsHasMore,
    isFetchingNextPage: gsFetchingNext,
    isPending: gsFeedIsPending,
    isError,
  } = useInfiniteQuery({
    queryKey: ['gsFeedData', feedSlug, userId],
    queryFn: fetchFeed,
    getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
  })
  const allPosts = gsFeedData?.pages.flatMap((page) => page.results) || []

  useEffect(() => {
    const callback = (data) => {
      queryClient.invalidateQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
    }
    const successCallback = () => {}
    const failCallback = (data) => {
      console.error('subscribe something went wrong, check the console logs', data)
    }
    const subscription = feed.subscribe(callback).then(successCallback, failCallback)
    return () => subscription?.cancel && subscription.cancel()
  }, [])

  const findNewPeopleLink = useLink({
    href: '/discover',
  })

  const media = useMedia()
  const themeName = useThemeName()
  const isDarkTheme = themeName.includes('dark')
  const [ref, isInViewport] = useInViewport({ threshold: 0.1 })
  useEffect(() => {
    if (isInViewport && gsHasMore) {
      fetchNextPage()
    }
  }, [isInViewport, gsHasMore])

  if (gsFeedIsPending) {
    return <Spinner />
  }

  return (
    <YStack>
      {allPosts && (
        <FlatList
          data={allPosts}
          renderItem={(post) => <Post post={post} userId={userId} feedSlug={feedSlug} />}
          keyExtractor={(post) => post.id}
        />
      )}
      <YStack ref={ref} height={1} marginBottom={30}></YStack>
      <YStack marginBottom={30} justifyContent="center" alignItems="center" gap="$3">
        {gsFetchingNext && (
          <>
            <Spinner />
            <Text>Loading more posts...</Text>
          </>
        )}
        {!gsHasMore && <Text>No more posts.</Text>}
      </YStack>
      {allPosts && feedSlug !== 'user' && allPosts.length === 0 && (
        <YStack justifyContent="center">
          <XStack justifyContent="center" width="100%" height={media.sm ? 200 : 400}>
            <SolitoImage src={isDarkTheme ? emptyFeedDark : emptyFeed} alt="Empty feed" fill />
          </XStack>
          <XStack justifyContent="center" marginTop="$3" marginBottom="$6">
            <Button {...findNewPeopleLink} variant="primary">
              Find new people
            </Button>
          </XStack>
        </YStack>
      )}
    </YStack>
  )
}
